import React from "react"

import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { openPopupWidget } from "react-calendly"

export default function ContactIndex({data}) {

    const officePhoto = data.file.childImageSharp.gatsbyImageData;

    return <>
        <Seo
            title="Contact"
            description="Find out how to get in contact with Opworks, your trusted partner for all things AWS cloud. We're easy to reach by phone or email or better still why not visit us in person at our office in Chelmsford, Essex (UK)."
        />

        <PageTitle summary="Since 2017, the Innovation Centre on the Anglia Ruskin University campus is where we call home, it provides an excellent office environment for our staff along with great facilities for face-to-face client meetings."
                   title="Contact"/>

        <div className={"relative -mb-8 xl:-mb-24 z-10"}>

            <div className={"w-full xl:w-1/2 xl:mx-auto px-6 md:px-12 xl:px-0"}>

                <div className={" rounded-lg shadow-lg bg-white border-gray-100"}>
                    <div className={"rounded-lg shadow-xs overflow-hidden p-8 border"}>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className={"prose prose-lg"}>
                                <h2 className="text-base leading-6 text-indigo-600 font-display font-bold uppercase">Email</h2>
                                <p className={"mb-4 md:mb-0"}><a href="mailto:hello@opworks.co.uk">hello@opworks.co.uk</a></p>
                            </div>
                            <div className={"prose prose-lg"}>
                                <h2 className="text-base leading-6 text-indigo-600 font-display font-bold uppercase">Telephone</h2>
                                <p><a href="tel:+441245646465">01245 64 64 65</a></p>
                                <div className="inline-flex rounded-md shadow mb-4 md:mb-0">
                                    <a href="https://calendly.com/mtwalsh/30min" onClick={(e) => {
                                        e.preventDefault();
                                        openPopupWidget({
                                            url: 'https://calendly.com/mtwalsh/30min',
                                            pageSettings: {
                                                'hideEventTypeDetails': true,
                                                'hideLandingPageDetails': true,
                                            }
                                        });
                                        return false;
                                    }}
                                       className="inline-flex no-underline items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring transition duration-150 ease-in-out">
                                        Book a call now
                                    </a>
                                </div>
                            </div>
                            <div className={"prose prose-lg"}>
                                <h2 className="text-base leading-6 text-indigo-600 font-display font-bold uppercase">Address</h2>
                                <p className={"prose-sm"}>BIC 215 - Arise Chelmsford<br/>
                                    Alan Cherry Drive<br/>
                                    Chelmsford, Essex<br/>
                                    CM1 1SQ<br/>
                                    United Kingdom</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <GatsbyImage image={officePhoto} imgStyle={{zIndex: '-1'}} />

    </>;
}

export const query = graphql`{
  file(relativePath: {eq: "arise-chelmsford.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
